<template>
  <footer
    class="p-footer"
    itemscope
    itemtype="http://schema.org/WPFooter"
  >
    <div v-if="timeStamp">
      <table>
        <thead>
          <tr>
            <th>url</th>
            <th>TimeStart</th>
            <th>TimeRange</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(timeLine, index) in timeStamp"
            :key="'timeLine' + index"
          >
            <td>{{ timeLine.url }}</td>
            <td>{{ timeLine.timeStart }}</td>
            <td>{{ timeLine.timeDif }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="p-footer__main container d-flex justify-between align-center">
      <a
        :href="logoUrl"
        class="p-footer__logo"
        :style="`background: url(${
          basePath + provider.provider.logo_partner
        }) 0 50% / contain no-repeat;`"
        @click.prevent="goHome"
      >
        ProviderLogo
      </a>
      <nav class="p-footer__nav">
        <a
          v-if="provider?.provider?.lk_url"
          class="black--text subhead-2"
          :href="provider.provider.lk_url"
          target="_blank"
        >Личный кабинет{{ provider?.provider.id === 6 && (currentCity.fias_id === '140e31da-27bf-4519-9ea0-6185d681d44e' || currentCity.fias_id === '555e7d61-d9a7-4ba6-9770-6caa8198c483') ? ' МТС' : '' }}</a>
        <a
          class="black--text subhead-2"
          href="/locations/"
          @click.prevent="$router.push(`/locations/`)"
        >Все города</a>
        <a
          v-if="!segmentationActive"
          class="black--text subhead-2"
          href="/contacts/"
          @click.prevent="$router.push(`/contacts/`)"
        >Контакты</a>
      </nav>
    </div>

    <div class="p-footer__tariffs container">
      <p class="black--text fwm">
        Тарифы
      </p>
      <menu
        class="p-footer__links d-flex"
        itemscope
        itemtype="http://schema.org/SiteNavigationElement"
      >
        <li
          v-for="link in linkListAvailable"
          :key="link.id"
        >
          <nuxt-link
            itemprop="url"
            class="gray-dark--text"
            :to="`/${currentCity.url}/${link.url}/`"
          >{{ link.name }}</nuxt-link>
        </li>
      </menu>
    </div>

    <div class="p-footer__sub">
      <div class="container d-flex justify-between flex-column">
        <meta
          :content="year"
          itemprop="copyrightYear"
        >
        <meta
          content="ООО «Интернет-Тариф»"
          itemprop="copyrightHolder"
        >
        <a
          target="_blank"
          href="/legal/privacy/"
          class="subhead-6 black--text text-underline"
        >Политика обработки персональных данных</a>
        <p class="subhead-6">
          &copy;
          {{ year }}
          Cайт не является средством массовой информации и действует на
          основании партнерского договора с {{ provider.provider.name }}.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { basePath } from '~/api'
import { timeArray, timeEnter } from '~~/api/openapi/common'
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'

const year = new Date().getFullYear()
const ctx = useNuxtApp()
const cityStore = useCities()
const mainStore = useMainStore()
const tariffsStore = useTariffs()
const houseHash = computed(() => tariffsStore.getHouseHash)
const houseUrl = computed(() => tariffsStore.getHouseUrl)
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const currentCity = computed(() => cityStore.getCity)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const $route = useRoute()
const fetching = ref(false)

const provider = computed(() => mainStore.getDomainConfig)
const timeStamp = computed(() => data.value?.timeStamp)

const logoUrl = computed(() => `/${currentCity.value?.url}/`)
const $router = useRouter()
const goHome = () => {
  $router.push(`/${currentCity.value?.url}/`)
}

const linkList = ref([
  {
    name: 'Интернет',
    url: 'internet',
    id: 1,
  },
  {
    name: 'Интернет + ТВ',
    url: 'internet-tv',
    id: 11,
  },
  {
    name: 'Интернет + Моб. связь',
    url: 'internet-mobile',
    id: 14,
  },
  {
    name: 'Интернет + ТВ + Моб. связь',
    url: 'internet-tv-mobile',
    id: 13,
  },
])
const linkListAvailable = computed(() => {
  return houseUrl.value && availableLinks.value.length
    ? linkList.value.filter((el: any) => availableLinks.value.includes(el.id))
    : linkList.value.filter((el: any) =>
      availableCityLinks.value.includes(el.id),
    )
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const client = ctx._apolloClients.default
const getLinks = async (city: boolean) => {
  if (!currentCity.value?.fias_id) return

  const res = (
    await client.query({
      query: gql`
        query getPartnersAvailableService(
          $input: LocationInfoInput!
          $tariffsInput2: BaseTariffsInput
        ) {
          location {
            locationTerms(input: $input) {
              tariffs(input: $tariffsInput2) {
                agg {
                  allAvailableServiceGroups {
                    id
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        input: {
          hash:
            houseUrl.value && !city
              ? houseHash.value || undefined
              : undefined,
          url: houseUrl.value && !city ? houseUrl.value : undefined,
          addrObjFiasId: currentCity.value.fias_id,
          strictTechs: true,
        },
        tariffsInput2: {
          providerSlugs: provider.value?.provider?.slug,
        },
      },
      context: {
        headers: getCommonHeaders.value,
      },
      fetchPolicy: 'no-cache',
    })
  ).data.location.locationTerms.tariffs.agg.allAvailableServiceGroups

  return res
}

const availableLinks = computed(
  () => data.value?.availableLinks?.map((el: any) => +el.id) || [],
)
const availableCityLinks = computed(
  () => data.value?.availableCityLinks?.map((el: any) => +el.id) || [],
)

let errorStatus
let errorMessage
const { data, error } = await useAsyncData('p-footer' + (currentCity.value?.fias_id || '') + (houseUrl.value || ''), async () => {
  let timeStamp
  let availableLinks: any
  let availableCityLinks: any

  const timeStampFetch = async () => {
    if ($route.query.debug === '1') {
      timeStamp = timeArray
    }
    else timeStamp = undefined
  }
  const availableLinksFetch = async () => {
    availableLinks = await getLinks(false)
  }
  const availableCityLinksFetch = async () => {
    availableCityLinks = await getLinks(true)
  }

  await Promise.all([
    timeStampFetch(),
    availableLinksFetch(),
    availableCityLinksFetch(),
  ])

  return {
    timeStamp,
    availableLinks,
    availableCityLinks,
  }
})

if (error.value) {
  throw createError({
    statusCode: errorStatus,
    fatal: true,
    message: errorMessage,
  })
}

watch(
  () => currentCity.value?.fias_id,
  async () => {
    if (data.value && !fetching.value) {
      data.value.availableLinks = await getLinks(false)
      data.value.availableCityLinks = await getLinks(true)
    }
  },
)
watch(
  () => houseUrl.value,
  async () => {
    if (data.value && !fetching.value) {
      data.value.availableLinks = await getLinks(false)
    }
  },
)
</script>

<style scoped lang="scss">
.p-footer {
  background-color: color(gray-p);
  padding-top: 40px;

  &__logo {
    display: inline-flex;
    font-size: 0;
    height: 50px;
    width: 200px;
    @media (max-width: getBreakpoint(tablet)) {
      width: 160px;
      height: 40px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 140px;
      height: 35px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 120px;
      height: 30px;
    }
  }

  &__main {
    margin-bottom: 56px;
    position: relative;
  }

  &__tariffs {
    margin-bottom: 64px;
    p {
      margin-bottom: 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 15px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: fontSize(paragraph);
    }
  }

  &__links {
    li {
      margin-bottom: 8px;
    }
    a {
      transition: color 0.24s;
      margin-right: 40px;
      &:hover {
        color: color(black);
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
    }
  }

  &__nav {
    a {
      text-decoration: none;
      margin-left: 40px;
      transition: color 0.24s;
      &:hover {
        color: var(--mainColor);
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: calc(100% + 56px);
      right: 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 15px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: fontSize(paragraph);
      right: 8px;
    }
  }

  &__sub {
    padding-top: 32px;
    padding-bottom: 32px;
    border-top: 1px solid color(gray-darker);
    a {
      margin-bottom: 16px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 12px;
      }
    }
  }
}
</style>
